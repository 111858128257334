$('.lojas-mapa .js-showmap').on('click', function(){
    $('.js-showmap').removeClass('active');
    $(this).addClass('active');
    id = $(this).data('map_id');
    $('.map-content').removeClass('active');
    $('.map-content.map-content-'+id).addClass('active');
    return false;
});
$('.slider-desktop').on('init', function(slick){ 
    $('.slick-prev.slick-arrow').addClass('seta-esquerda').html('<i class="fa fa-angle-left" aria-hidden="true"></i>');
    $('.slick-next.slick-arrow').addClass('seta-direita').html('<i class="fa fa-angle-right" aria-hidden="true"></i>');
  });

  $('.fotos-do-carro').on('init', function(slick){ 
    $('.slick-prev.slick-arrow').addClass('seta-esquerda').html('<i class="fa fa-angle-left" aria-hidden="true"></i>');
    $('.slick-next.slick-arrow').addClass('seta-direita').html('<i class="fa fa-angle-right" aria-hidden="true"></i>');
  });

$('#carousel-dif').on('init', function(slick){ 
    $('.slick-prev.slick-arrow').addClass('seta-esquerda').html('<i class="fa fa-angle-left" aria-hidden="true"></i>');
    $('.slick-next.slick-arrow').addClass('seta-direita').html('<i class="fa fa-angle-right" aria-hidden="true"></i>');
  });
$("body").on('init', '.slider-desktop', function () {
    $('.slider-desktop').css('visibility', 'visible');
});
$('.slider-desktop').slick({
  dots: false,
  infinite: true,
  autoplay: true,
  speed: 300,
  slidesToShow: 1,
  adaptiveHeight: true,
});
$("body").on('init', '.slider-mobile', function () {
  $('.slider-mobile').css('visibility', 'visible');
});
$('.slider-mobile').slick({
  dots: false,
  infinite: true,
  autoplay: true,
  arrows: false,
  speed: 300,
  slidesToShow: 1,
  adaptiveHeight: true
});

$('.fotos-do-carro').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  arrows: true,
  fade: false,
  asNavFor: '.galeria-thumb'
});

$('.galeria-thumb').slick({
  slidesToShow: 5,
  slidesToScroll: 1,
  asNavFor: '.fotos-do-carro',
  arrows: false,
  dots: false,
  centerMode: false,
  focusOnSelect: true,
  verticalSwiping: true
});
$("#carousel-dif").slick({  
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  dots: false,
  verticalSwiping: true,
  autoplay: true,  
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    ]
});

//Visualizador de video
$('.popup-youtube').magnificPopup({
  type: 'iframe',
      iframe: {
        markup: '<div class="mfp-iframe-scaler">'+
                  '<div class="mfp-close"></div>'+
                  '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
                  '<a href="" class="btn-medium btn-full btn-main-color">Mais informações</a>'+
                '</div>',
        srcAction: [
          'iframe_src'
        ]
      },
      callbacks: {
        markupParse: function(template, values, item) {
          template.find('a').attr('href', $(item.el[0]).data('car_url'));
          console.log(template, values, item);
        },
      }
});

$(".btn-mais-filtro").click(function(){
  if($(this).hasClass("mais")){
    $(this).removeClass("mais");
    $(this).addClass("menos");
    $(this).children("span").html("Menos filtros");
  }else{
    $(this).addClass("mais");
    $(this).removeClass("menos");
    $(this).children("span").html("Mais filtros");
  }
});

$('body').on('change', '.select-marca', function() {
var id_marca = $(this).val();
var $this = $(this);
carregarModelos(id_marca, $this);
});

if($('.select-marca').length > 0) {
$('.select-marca').each(function(index) {
  var id_marca = $(this).val();
  var $this = $(this);
  if($this.closest('form').find('.select-modelo').children().length <= 1){
    carregarModelos(id_marca, $this);
  }
});
}

$('.form-ajax').ajaxForm({
  dataType: 'json',
  beforeSubmit: function (arr, $form, options) {
    $('.submit-action').attr('disabled', 'disabled');
    startLoading();
  },
  success: function (json, status, xhr, form) {
    var id_form = form[0].id;

    if (json.erro != "") {
      alertify.minimalDialog("Erro", json.erro);
    } else {
      alertify.minimalDialog("Sucesso", json.sucesso);
      $('#' + id_form)[0].reset();
    }
  },
  error: function () {
    alertify.minimalDialog("Erro", "Erro ao processar formulário.<br>Tente novamente mais tarde.");
  },
  complete: function () {
    $('.submit-action').removeAttr('disabled');
    stopLoading();
  }
});   

$(".busca li a").click(function(){
	$(this).toggleClass('busca-estoque-ativa');
	$(this).parent().find('.sub-opcoes').slideToggle();

});

$('.ano_min_refresh, .ano_max_refresh').on('change', function(){
  //refreshPage($(this).attr('name'), $(this).val());
});
$('.limit_refresh').on('change', function () {
  refreshPage($(this).attr('name'), $(this).val());
});

$('body').on('click', '.js-modal-click-action', function(){
  var assunto = $(this).data('id_assunto');
  var seminovo = $(this).data('id_seminovo');
  console.log(assunto);
  if(assunto){
    $('.js-form-recebe-dados').find('.id_assunto').val(assunto);
  }
  if(seminovo){
    $('.js-form-recebe-dados').find('.id_seminovo').val(seminovo);
  }

});

$(".mask--ano").inputmask("9999");
$(".mask--data").inputmask("99/99/9999");
$(".mask--cep").inputmask("99999-999");
$(".mask--cpf").inputmask("999.999.999-99");
$(".mask--cnpj").inputmask("99.999.999/9999-99");
$(".mask--placa").inputmask("aaa-9*99");
$('.mask--phone').inputmask({
        mask: ["(99)9999-9999", "(99)99999-9999", ],
        keepStatic: true
      });
$(".mask--money").inputmask( 'currency',{"autoUnmask": true,
radixPoint:",",
groupSeparator: ".",
allowMinus: false,
prefix: 'R$ ',            
digits: 2,
digitsOptional: false,
rightAlign: false,
unmaskAsNumber: true
});
//$(".mask--ano").mask("9999");
//$(".mask--data").mask("99/99/9999");
//$(".mask--cep").mask("99999-999");
//$(".mask--cpf").mask("999.999.999-99");
//$(".mask--cnpj").mask("99.999.999/9999-99");
//$(".mask--placa").mask("aaa-9999");
//$('.mask--phone')
//.mask("(99)9999-9999?9", { placeholder: "" })
//.blur(function (event) {
//  var target, phone, element;
//  target = (event.currentTarget) ? event.currentTarget : event.srcElement;
//  phone = target.value.replace(/\D/g, '');
//  element = $(target);
//  element.unmask();
//  if(phone.length > 10) {
//    element.mask("(99)99999-999?9", { placeholder: "" });
//  } else {
//    element.mask("(99)9999-9999?9", { placeholder: "" });
//  }
//});


 //Adicionar clique do banner
$('.btn-banner-click').on('click', function (event) {
  var id_banner = $(this).find("img").data('id_banner');
  clickBanner(id_banner);
});



$('body').on('click', '.js-modal-click-action', function(){
  var assunto = $(this).data('id_assunto');
  var seminovo = $(this).data('id_seminovo');
  var banner = $(this).data('id_banner');
  console.log(assunto);
  if(assunto){
    $('.js-form-recebe-dados').find('.id_assunto').val(assunto);
  }
  if(seminovo){
    $('.js-form-recebe-dados').find('.id_seminovo').val(seminovo);
  }
  if(banner){
    $('.js-form-recebe-dados').find('.id_banner').val(banner);
  }
});

$('.botao-tenho-interesse-lead').on('click', function(){
  $('html').animate({scrollTop: $("#cta-interesse").offset().top}, 800);
  $(this).fadeOut();
  return false;
});
if($('.botao-tenho-interesse-lead').length > 0){
  $(window).on('scroll', function(){
    pageOffset = window.pageYOffset;
    ctaOffsetTop = parseInt($("#cta-interesse").offset().top);
    ctaOffsetBottom = parseInt($("#cta-interesse").height()) + ctaOffsetTop;
    if(
        (pageOffset >= ctaOffsetTop-300 && pageOffset < ctaOffsetBottom-400)
      ){
      $('.botao-tenho-interesse-lead').fadeOut();
    }else{
      $('.botao-tenho-interesse-lead').fadeIn();
    }
  });
}

$('.menu-overlay').on('click', function(){
    closeMenu();
    history.back();
});
$(".btn-menu-mobile").click(function () {
    if ($(this).hasClass('open')) {
        closeMenu();
        history.back();
    } else {
        openMenu();
        window.history.pushState({menu: 'open'}, "Menu", "#menu");
    }
});

window.onpopstate = function (event) {
    if ($('.btn-menu-mobile').hasClass('open')) {
        closeMenu();
    }
};


function openMenu(){
    $(".btn-menu-mobile").addClass('open');
    $(".menu-top").addClass("menu-show");
    $("body").addClass("block-overflow");
    $(".menu-overlay").addClass("show");
}

function closeMenu(){
    $(".btn-menu-mobile").removeClass('open');
    $(".menu-top").removeClass("menu-show");
    $("body").removeClass("block-overflow");
    $(".menu-overlay").removeClass("show");
}

function clickBanner(id_banner){
    $.ajax({
        url: root_rel+'lua4auto/banners/click/'+id_banner,
        type: 'get'
    });
}

function startLoading(){
    $('.loading-wrapper').stop(true, true).fadeIn();
  }

function stopLoading(){
  $('.loading-wrapper').stop(true, true).fadeOut('fast');
}

function refreshPage(atributo, valor){
  var hash = {};
  var parser = document.createElement('a');

  parser.href = window.location.href;
  var parameters = parser.search.split(/\?|&/);
  for(var i=0; i < parameters.length; i++) {
      if(!parameters[i])
          continue;

      var ary      = parameters[i].split('=');
      hash[ary[0]] = ary[1];
  }

  hash[atributo] = valor;

  var list = [];
  Object.keys(hash).forEach(function (key) {
      list.push(key + '=' + hash[key]);
  });

  parser.search = '?' + list.join('&');
  window.location.href = parser.href;
}
 

function carregarModelos(id_marca, $this){
  if(id_marca != "" && id_marca > 0){
      $.ajax({
          url: root_rel + 'lua4auto/seminovosmodelos/busca/'+id_marca,
          type: 'get',
          beforeSend: function(){
              $this.closest('form').find('.select-modelo').html('<option value="0">Selecione primeiro uma marca</option>');
              startLoading();
          },
          dataType: 'json',
          success: function(json){
              var html = '<option value="">Selecione um modelo</option>';
              $.each(json, function(key, value) {
                  if($this.hasClass('marca-busca')){
                      if(value.qtd_carros > 0){
                          html += '<option value="'+value.id_modelo+'">'+value.descricao+'</option>';
                      }
                  }else{
                      html += '<option value="'+value.id_modelo+'">'+value.descricao+'</option>';
                  }
              });
              $this.closest('form').find('.select-modelo').html(html);
              $this.closest('form').find('.select-modelo').attr('disabled', false);
          },
          complete: function(){
              stopLoading();
          }
      });
  }
}

//carousel diferenciais


//Ajuste estoque sem recarregar
$(".form-estoque").submit(function(){
    $(this).find(":input").filter(function () {
        return !this.value;
    }).attr("disabled", true);

    return true;
});


$(".clique-menu").click(function(){
    $('.menu-mobile').slideToggle();
    $('.outros-botoes').slideToggle();
});

 if($('.car-right-box').length > 0){
    $(window).scroll(function() {
      var vpWidth = $(document).width();
      if (vpWidth > 1200) {
            var winTop = $(window).scrollTop();
        var pageContentTop = $('.box-interna-carro').offset().top - 120;

        var mainH = $('.box-interna-carro').height();
        var sidebarH = $('.car-right-box').height();

        var mainSideDiff = mainH - sidebarH +20; // 20 = main margin-bottom
        var stopPointBottom = pageContentTop + (mainSideDiff);

        if(winTop > pageContentTop && winTop < stopPointBottom) {
          var diff = winTop - pageContentTop;
          diff = diff - 80;
          if(diff < 0){ diff = 0; }
          $('.car-right-box').css('top', diff); // 10 = to give some space from top
        } else if(winTop > stopPointBottom) {
          
          
          $('.car-right-box').css('top', mainSideDiff);
        } else {
          $('.car-right-box').css('top', 0);
        }
      }
    });
  }

var anoveiculo = document.getElementById('anoveiculo');
var kmveiculo = document.getElementById('kmveiculo');
var valorveiculo = document.getElementById('valorveiculo');
if($(anoveiculo).length){
    noUiSlider.create(anoveiculo, {
        range: {
            'min': anoveiculoMin,
            'max': anoveiculoMax
        },
        step: 1,
        start: [anoveiculoMin, anoveiculoMax],
        margin: 0,
        connect: true,
        format: {
              to: function ( value ) {
                return parseInt(value);
              },
              from: function ( value ) {
                return parseInt(value);
              }
            },
        behaviour: 'tap-drag',
    //    tooltips: true
    });
    anoveiculo.noUiSlider.on('update', function (values, handle) {
        $('#slider-ano_' + handle).val(values[handle]);   
        $('#slider-ano-range').html(values.join(' até '));
    });
}
if($(kmveiculo).length){
    noUiSlider.create(kmveiculo, {
        range: {
            'min': kmMin,
            'max': kmMax
        },
        step: 5000,
        start: [kmMin, kmMax],
        margin: 0,
        connect: true,
        format: {
              to: function ( value ) {
                return parseInt(value);
              },
              from: function ( value ) {
                return parseInt(value);
              }
            },
        behaviour: 'tap-drag',
    //    tooltips: true
    });
    kmveiculo.noUiSlider.on('update', function (values, handle) {
        $('#slider-km_' + handle).val(values[handle]);
        values[0] = values[0].toLocaleString("pt-BR");
        if(values[1] == kmMax){
            values[1] = kmMaxLabel;
        }else{
            values[1] = values[1].toLocaleString("pt-BR");
        }
        $('#slider-km-range').html(values.join(' até '));
    });

}

if($(valorveiculo).length){
    noUiSlider.create(valorveiculo, {
        range: {
            'min': [valorMin],
            '70%': [70000],
            'max': [valorMax]
        },
        step: 5000,

        start: [valorMin, valorMax],
        connect: true,

        format: {
            to: function ( value ) {
              return parseInt(value);
            },
            from: function ( value ) {
              return parseInt(value);
            }
          },

        behaviour: 'tap-drag',
    //    tooltips: true
    });
    valorveiculo.noUiSlider.on('update', function (values, handle) {
        $('#slider-valor_' + handle).val(values[handle]);    
         values[0] = "R$ " + values[0].toLocaleString("pt-BR");
         if(values[1] == valorMax){
            values[1] = valorMaxLabel;
        }else{
            values[1] = "R$ " + values[1].toLocaleString("pt-BR");
        }
        $('#slider-valor-range').html(values.join(' até '));
    });
}